<template>
  <v-data-table
    :loading="loading"
    :disable-filtering="true"
    :disable-pagination="true"
    :hide-default-footer="true"
    :disable-sort="true"
    :headers="headers"
    :items="memo.attributes"
  >
    <template v-slot:top="{items}" v-if="enableKeys">
      <v-container>
        <v-row>
          <v-col class="d-flex" cols="4" xs="12">
            <v-select
              :items="viableKeyAttributes"
              item-value="name"
              :label="translate('Key attribute')"
              v-model="memo.key"
              >
              <template v-slot:selection="{item}">
                {{item.name}}
              </template>
              <template v-slot:item="{item}">
                {{item.name}}
              </template>
            </v-select>
          </v-col>
          <v-col class="d-flex" cols="4" xs="12">
            <v-select
              :clearable="true"
              :items="viableParentKeyAttributes"
              item-value="name"
              :label="translate('Parent key attribute')"
              v-model="memo.parentKey"
              @click:clear="memo.parentKey = null">
              <template v-slot:selection="{item}">
                {{item.name}}
              </template>
              <template v-slot:item="{item}">
                {{item.name}}
              </template>
            </v-select>
          </v-col>
          <v-col class="d-flex" cols="4" xs="12">
            <v-select
              :clearable="true"
              :items="viableChildIndexKeyAttributes"
              item-value="name"
              :label="translate('Child index attribute')"
              v-model="memo.childIndexKey"
              @click:clear="memo.childIndexKey = null"
              >
              <template v-slot:selection="{item}">
                {{item.name}}
              </template>
              <template v-slot:item="{item}">
                {{item.name}}
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-container>

    </template>
    <template v-slot:item.name="{item, value}">
      {{value}}
    </template>
    <template v-slot:item.newName="{item, value}">
      <v-text-field
        v-model="item.newName"
        :disabled="item.isKey"
        :placeholder="item.name"
        :rules="validationRules.identifier({optional: true})"/>
    </template>
    <template v-slot:item.type="{item, value}">
      <v-select
        :disabled="item.isKey"
        :items="attributeTypes"
        item-value="type"
        item-text="type"
        v-model="item.type" />
    </template>
    <template v-slot:item.label="{item, value}">
      <v-text-field v-model="item.label" :placeholder="item.name" />
    </template>
    <template v-slot:item.multimarket="{item, value}">
      <v-checkbox v-model="item.multimarket"  />
    </template>
    <template v-slot:item.deleted="{item, value}">
      <v-checkbox v-model="item.deleted" :disabled="item.isKey"/>
    </template>
  </v-data-table>
</template>
<script>
import {mapGetters} from 'vuex'
import diff from 'deep-diff'
import debounce from 'lodash/debounce'

const toMap = (list, keyFn, valueFn, initial = {}) => list.reduce((memo, item) => {
  let key = keyFn(item)
  if (key) {
    memo[key] = valueFn(item)
  }
  return memo
}, initial)

export default {
  props: ['loading', 'schema', 'enable-keys', 'disable-labels', 'disable-name', 'get-attribute-label'],
  data: () => ({
    pristineSchema: null,
    pristineConfig: null,
    memo: null,
  }),
  computed: {
    ...mapGetters(['api', 'suitesConfig', 'translate', 'validationRules']),
    headers () {
      return [ !this.disableName && {
        text: this.translate('Name', 'name'),
        value: 'name'
      }, {
        text: this.translate('New name', 'new-name'),
        value: 'newName'
      }, {
        text: this.translate('Type', 'type'),
        value: 'type'
      }, !this.disableLabels && {
        text: this.translate('Label', 'label'),
        value: 'label'
      }, {
        text: this.translate('Multimarket'),
        value: 'multimarket'
      }, {
        text: this.translate('Delete', 'delete'),
        value: 'deleted'
      }]
      .filter(h => h)
    },
    viableKeyAttributes () {
      return this.memo.attributes.filter(({type}) => type === 'string')
    },
    viableParentKeyAttributes () {
      return this.memo.attributes.filter(({type}) => type === 'string')
    },
    viableChildIndexKeyAttributes () {
      return this.memo.attributes.filter(({type}) => type === 'integer')
    }
  },
  asyncComputed: {
    attributeTypes: {
      async get () {
        return this.api.types.getTypes()
      },
      default: []
    }
  },
  watch: {
    schema: {
      deep: true,
      immediate: true,
      handler (schema) {
        this.initialMemo = null
        this.pristineSchema = null
        this.pristineConfig = null
        this.memo = this.schema2memo(schema)
      }
    },
    memo: {
      deep: true,
      immediate: true,
      handler: debounce(function (memo) {
        this.detectChanges(memo)
      }, 500)
    }
  },
  methods: {
    addNewAttribute () {
      this.memo.attributes.push({
        isNew: true,
        name: '',
        newName: 'new_attribute',
        label: '',
        type: 'string',
        multimarket: false
      })
    },

    detectChanges (memo) {
      const notify = (schema, config) => {
        this.$emit('update-schema', schema)
        !this.disableLabels && this.$emit('update-config', config)
      }

      if (this.pristineSchema === null) {
        this.pristineSchema = this.memo2schema(memo)
      }
      if (this.pristineConfig === null) {
        this.pristineConfig = this.memo2config(memo)
      }
      const schema = this.memo2schema(memo)
      const notifySchema = diff(schema, this.pristineSchema) ? schema : null

      const config = this.memo2config(memo)
      const notifyConfig = diff(config, this.pristineConfig) ? config : null

      return notify(notifySchema, notifyConfig)
    },

    schema2memo (schema) {
      let getLabel = this.getAttributeLabel || (({name}) => name)
      let {key} = schema || {}
      let memo = {
        ...schema
      }

      memo.attributes = Object.entries(memo.attributes || {})
        .filter(([name]) => this.enableKeys || (name != key))
        .map(([name, {type, multimarket}]) => ({
          name,
          type,
          newName: '',
          label: getLabel({name}),
          isKey: name === memo.key,
          isNew: false,
          multimarket,
          deleted: false
        }))
        .sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
      return memo
    },
    memo2schema (memo) {
      let keyOf = ({isNew, name, newName}) => isNew ? newName.trim() : name.trim()
      let nameOf = ({name, newName}) => newName.trim() || name.trim()

      let attributes = toMap(memo.attributes,
        keyOf,
        ({name, newName, type, multimarket, deleted}) => ({
          name: nameOf({name, newName}),
          type,
          multimarket,
          deleted
        }))

      let keyFixup = this.enableKeys ? {
        parentKey: memo.parentKey || null,
        childIndexKey: memo.childIndexKey || null,
      } : {}
      return {
        ...memo,
        ...keyFixup,
        attributes
      }
    },
    memo2config (memo) {
      let keyOf = ({isNew, name, newName}) => isNew ? newName.trim() : name.trim()
      return toMap(memo.attributes.filter(({deleted}) => !deleted),
        keyOf,
        ({label}) => ({
          label
        })
      )
    }
  }
}
</script>
