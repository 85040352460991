var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"loading":_vm.loading,"disable-filtering":true,"disable-pagination":true,"hide-default-footer":true,"disable-sort":true,"headers":_vm.headers,"items":_vm.memo.attributes},scopedSlots:_vm._u([(_vm.enableKeys)?{key:"top",fn:function(ref){
var items = ref.items;
return [_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"4","xs":"12"}},[_c('v-select',{attrs:{"items":_vm.viableKeyAttributes,"item-value":"name","label":_vm.translate('Key attribute')},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,true),model:{value:(_vm.memo.key),callback:function ($$v) {_vm.$set(_vm.memo, "key", $$v)},expression:"memo.key"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"4","xs":"12"}},[_c('v-select',{attrs:{"clearable":true,"items":_vm.viableParentKeyAttributes,"item-value":"name","label":_vm.translate('Parent key attribute')},on:{"click:clear":function($event){_vm.memo.parentKey = null}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,true),model:{value:(_vm.memo.parentKey),callback:function ($$v) {_vm.$set(_vm.memo, "parentKey", $$v)},expression:"memo.parentKey"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"4","xs":"12"}},[_c('v-select',{attrs:{"clearable":true,"items":_vm.viableChildIndexKeyAttributes,"item-value":"name","label":_vm.translate('Child index attribute')},on:{"click:clear":function($event){_vm.memo.childIndexKey = null}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,true),model:{value:(_vm.memo.childIndexKey),callback:function ($$v) {_vm.$set(_vm.memo, "childIndexKey", $$v)},expression:"memo.childIndexKey"}})],1)],1)],1)]}}:null,{key:"item.name",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"item.newName",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-text-field',{attrs:{"disabled":item.isKey,"placeholder":item.name,"rules":_vm.validationRules.identifier({optional: true})},model:{value:(item.newName),callback:function ($$v) {_vm.$set(item, "newName", $$v)},expression:"item.newName"}})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-select',{attrs:{"disabled":item.isKey,"items":_vm.attributeTypes,"item-value":"type","item-text":"type"},model:{value:(item.type),callback:function ($$v) {_vm.$set(item, "type", $$v)},expression:"item.type"}})]}},{key:"item.label",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-text-field',{attrs:{"placeholder":item.name},model:{value:(item.label),callback:function ($$v) {_vm.$set(item, "label", $$v)},expression:"item.label"}})]}},{key:"item.multimarket",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-checkbox',{model:{value:(item.multimarket),callback:function ($$v) {_vm.$set(item, "multimarket", $$v)},expression:"item.multimarket"}})]}},{key:"item.deleted",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-checkbox',{attrs:{"disabled":item.isKey},model:{value:(item.deleted),callback:function ($$v) {_vm.$set(item, "deleted", $$v)},expression:"item.deleted"}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }